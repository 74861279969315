<template>
  <div class="page-404 d-flex align-items-center min-vh-100 text-center">
    <CContainer>
      <CRow class="w-100 justify-content-center">
        <CCol md="6">
          <div class="w-100">
            <div class="clearfix">
              <h1 class="float-left display-3 mr-4">404</h1>
              <h4 class="pt-3">Oops! Você está perdido</h4>
              <p class="text-muted">
                A página que você está procurando não existe
              </p>
            </div>
          </div>
        </CCol>
      </CRow>
      <CRow class="w-100 justify-content-center">
        <CCol col="6" sm="6" md="4" lg="3">
          <CButton
            color="primary"
            class="my-3"
            variant="outline"
            :block="true"
            @click="goToHome"
          >
            Voltar para a home
          </CButton>
        </CCol>
      </CRow>
    </CContainer>
    <div id="dot"></div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Page 404',
    titleTemplate: ' %s - Manager - OW Interactive'
  },

  mounted() {
    this.dotAnimation()
  },

  methods: {
    dotAnimation() {
      window.addEventListener('mousemove', function (e) {
        const dot = document.getElementById('dot')

        if (dot.length === 0) {
          return
        }

        const dotSize = 300

        dot.style.width = dotSize + 'px'
        dot.style.height = dotSize + 'px'

        const mouseX = e.clientX
        const mouseY = e.clientY

        // for centering pointer inside circle on x axis
        const coorX = mouseX - dotSize / 2

        // for centering pointer inside circle on y axis
        const coorY = mouseY - dotSize / 2

        dot.style.left = coorX + 'px'
        dot.style.top = coorY + 'px'
      })
    },

    goToHome() {
      window.location.href = '/'
    }
  }
}
</script>

<style lang="scss">
.page-404 {
  overflow: hidden;
  position: relative;

  h1 {
    font-size: 15em;
    width: 100%;
  }

  .btn-link {
    line-height: 19px;
  }

  #dot {
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    z-index: -10;
  }
}
</style>
